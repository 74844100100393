exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-book-tsx": () => import("./../../../src/pages/book.tsx" /* webpackChunkName: "component---src-pages-book-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-me-compvision-tsx": () => import("./../../../src/pages/me/compvision.tsx" /* webpackChunkName: "component---src-pages-me-compvision-tsx" */),
  "component---src-pages-me-tsx": () => import("./../../../src/pages/me.tsx" /* webpackChunkName: "component---src-pages-me-tsx" */),
  "component---src-pages-my-gf-tsx": () => import("./../../../src/pages/my-gf.tsx" /* webpackChunkName: "component---src-pages-my-gf-tsx" */),
  "component---src-pages-navigation-tsx": () => import("./../../../src/pages/navigation.tsx" /* webpackChunkName: "component---src-pages-navigation-tsx" */),
  "component---src-pages-preface-tsx": () => import("./../../../src/pages/preface.tsx" /* webpackChunkName: "component---src-pages-preface-tsx" */)
}

